import { Dispatch, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { axiosGetCounter, axiosPostFile } from "../../api";
import { Counter, ISubscribe } from "../../types";

type initStateType = {
  items: any[];
  counterNumber: string;
  counter: ISubscribe | null;
  hasProtocol: boolean;
  errorMessage: string;
  isLoading: boolean;
};

const initState: initStateType = {
  items: [],
  counterNumber: "123456",
  counter: null,
  hasProtocol: false,
  errorMessage: "",
  isLoading: false,
};

const projectsSlice = createSlice({
  name: "tasklist",
  initialState: initState,
  reducers: {
    setProjectsData: (state, action: PayloadAction<any[]>) => {
      state.items = action.payload;
    },
    setCounterNumber: (state, action: PayloadAction<string>) => {
      state.counterNumber = action.payload;
    },
    setHasProtocol: (state, action: PayloadAction<boolean>) => {
      state.hasProtocol = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setCounter: (state, action: PayloadAction<ISubscribe>) => {
      state.counter = action.payload;
    },
    resetCounter: (state) => {
      state.counter = null;
      state.hasProtocol = false;
    },
    setErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
  },
});

export const getDataThunk =
  (counter: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(setIsLoading(true));
    const data = await axiosGetCounter(counter);
    if (data.resultCode === 0) {
      dispatch(setErrorMessage(data.errorMessage));
      dispatch(setCounter(data.data.counter));
      dispatch(setHasProtocol(data.data.hasProtocol));
    } else {
      dispatch(resetCounter());
      dispatch(setErrorMessage(data.errorMessage));
    }
    dispatch(setIsLoading(false));
    console.log("test", data);
    //@ts-ignore
  };

export const sendFileThunk =
  (file: FormData) => async (dispatch: Dispatch, getState: () => RootState) => {
    const data = await axiosPostFile(file);
    if (data.resultCode === 0) {
      console.log("0");
    } else {
      console.log("1");
    }
    // console.log("test", data);
    //@ts-ignore
  };

// Extract the action creators object and the reducer
const { actions, reducer } = projectsSlice;
// Extract and export each action creator by name
export const {
  setProjectsData,
  setCounterNumber,
  setCounter,
  setHasProtocol,
  setIsLoading,
  setErrorMessage,
  resetCounter,
} = actions;
// Export the reducer, either as a default or named export
export default reducer;
