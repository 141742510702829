import axios, { AxiosResponse } from "axios";
import { Counter, ISubscribe } from "../types";

export const axInstance = axios.create({
  // baseURL: "http://localhost:5000/api",
  // baseURL: "https://povirka.onrender.com/api",
  // baseURL: "https://povirka-next.vercel.app/api",
  // baseURL: "http://localhost:8000/api",
  baseURL: "https://api.povirka.mk.ua/api",
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
  xsrfHeaderName: "X-XSRF-TOKEN",
});

export const axiosGetCounter = (number: string) => {
  type GetCounterResponseType = {
    resultCode: number;
    errorMessage: string;
    data: {
      counter: ISubscribe;
      hasProtocol: boolean;
    };
  };
  return (
    axInstance
      // .get(`/accounts/counter?zvtNumber=${number}`)
      .get(`/public-counter?zvtNumber=${number}`)
      .then((res: AxiosResponse<GetCounterResponseType>) => {
        console.log(res.data);
        return res.data;
      })
      .catch((error) => {
        console.log(error);
        return {
          resultCode: 1,
          errorMessage: error.message,
        } as GetCounterResponseType;
      })
  );
};

export const axiosPostFile = (formData: FormData) => {
  console.log("formData", formData);
  console.log("formData", formData);
  console.log("123");
  type GetCounterResponseType = {
    resultCode: number;
    errorMessage: string;
    source: {
      data: {
        counter: Counter;
      };
    };
  };

  console.log("axiosPostFile", formData.get("file"));

  // return axInstance.get(`/accounts/ballance?email=${email}`).then((res: AxiosResponse<GetBalanceResponseType>) => {
  //     return res.data
  // })
  return axInstance
    .post(`/accounts/loadfile`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res: AxiosResponse<GetCounterResponseType>) => {
      console.log(res);
      return res.data;
    });
};
