import React, { useEffect } from "react";
import CardInfo from "./CardInfo";

interface Props {
  title: string;
  testAllowableError: number;
  testConsuptionStatus: string;
  testActualConsumption: number;
  testInitialValue: number;
  testEndValue: number;
  testTestDuration: string;
  testTestNumber: string;
  testVolumeOfStandard: string;
  testBeginPhoto: string;
  testEndPhoto: string;
  generalConsumption: string;
}

export function correctImage(image: string) {
  console.log(image.replaceAll(" ", "+").slice(-2).slice(0, 1) === "Z");
  if (image.replaceAll(" ", "+").slice(-2).slice(0, 1) === "Z") {
    return image.replaceAll(" ", "+").slice(0, -1);
  } else {
    return image.replaceAll(" ", "+");
  }
}

export const TestCard: React.FC<Props> = ({
  title,
  testAllowableError,
  testConsuptionStatus,
  testActualConsumption,
  testEndValue,
  testInitialValue,
  testTestDuration,
  testTestNumber,
  testVolumeOfStandard,
  testBeginPhoto,
  testEndPhoto,
  generalConsumption,
}) => {
  return (
    <div className="flex flex-col gap-y-1">
      <div className="text-center bg-[#2B7AF1] rounded-md text-white font-semibold">
        {title}
      </div>
      <CardInfo
        title="Задана об'ємна витрата, м³/год"
        value={generalConsumption}
      />
      <CardInfo
        title="Допустима похибка, %"
        value={testAllowableError.toString()}
      />
      <CardInfo title="Об'єм за еталоном, дм³" value={testVolumeOfStandard} />
      <CardInfo
        title="Об'єм за лічильником, дм³"
        value={(testEndValue - testInitialValue).toFixed(2).toString()}
      />

      <CardInfo title="Час вимірювання, с" value={testTestDuration} />
      {/* <CardInfo title="testTestNumber ?" value={testTestNumber} /> */}
      <CardInfo
        title="Фактична похибка, %"
        // value={(100 - ((testEndValue - testInitialValue) / +testVolumeOfStandard) * 100).toFixed(2).toString()}
        value={(
          ((testEndValue - testInitialValue - +testVolumeOfStandard) /
            +testVolumeOfStandard) *
          100
        )
          .toFixed(2)
          .toString()}
        cStyle={`${
          Number(
            Math.abs(
              ((testEndValue - testInitialValue - +testVolumeOfStandard) /
                +testVolumeOfStandard) *
                100
            ).toFixed(2)
          ) > testAllowableError
            ? "text-red-600"
            : "text-green-600"
        }`}
      />
      <CardInfo
        title="Фактична витрата, м³/год"
        value={(testActualConsumption / 1000).toFixed(2).toString()}
      />
      <CardInfo
        title="Значення напочатку"
        value={testInitialValue.toString()}
      />
      <CardInfo title="Значення наприкінці" value={testEndValue.toString()} />
      <CardInfo
        title="Статус витрати"
        value="В діапазоні"
        cStyle="text-green-600"
      />
      <CardInfo
        title="Результат тесту"
        value={
          Number(
            Math.abs(
              ((testEndValue - testInitialValue - +testVolumeOfStandard) /
                +testVolumeOfStandard) *
                100
            ).toFixed(2)
          ) <= testAllowableError
            ? "Придатний"
            : "Не придатний"
        }
        cStyle={`${
          Number(
            Math.abs(
              ((testEndValue - testInitialValue - +testVolumeOfStandard) /
                +testVolumeOfStandard) *
                100
            ).toFixed(2)
          ) <= testAllowableError
            ? "text-green-600"
            : "text-red-600"
        }`}
      />
      <div>
        <img
          className="rounded-lg hover:scale-[1.7]"
          width={600}
          src={`data:image/png;base64,${correctImage(testBeginPhoto)}`}
        ></img>
      </div>
      <div>
        <img
          className="rounded-lg hover:scale-[1.7]"
          width={600}
          src={`data:image/png;base64,${correctImage(testEndPhoto)}`}
        ></img>
      </div>
    </div>
  );
};
