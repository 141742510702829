import React from "react";

interface Props {
  img: string;
  text: string;
  alt: string;
}

export const ServiceCard: React.FC<Props> = ({ img, text, alt }) => {
  return (
    <div className="max-w-[300px] max-sm:w-full max-sm:max-w-full rounded-[10px] shadow-[0_2px_6px_rgba(0,0,0,0.1)] flex flex-col items-center justify-center text-center gap-y-4 p-8">
      <img alt={alt} src={img} />
      <p className="font-medium">{text}</p>
    </div>
  );
};
