export const processDate = (dateString: string, addYears?: boolean): string => {
  // Убираем время, оставляем только часть с датой
  const [datePart] = dateString.split(" ");

  // Разделяем дату на день, месяц и год и приводим их к числам
  const [day, month, year] = datePart.split(".").map(Number);

  // Проверяем корректность данных
  if (isNaN(day) || isNaN(month) || isNaN(year)) {
    throw new Error("Invalid date format");
  }

  // Создаем объект даты
  const date = new Date(year, month - 1, day);

  // Добавляем 4 года
  if (addYears) date.setFullYear(date.getFullYear() + 4);

  // Форматируем дату обратно в строку
  const updatedDay = String(date.getDate()).padStart(2, "0");
  const updatedMonth = String(date.getMonth() + 1).padStart(2, "0"); // Месяцы в JS начинаются с 0
  const updatedYear = date.getFullYear();

  return `${updatedDay}.${updatedMonth}.${updatedYear}`;
};
