import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { processDate } from "../../../utils/formatDate";
import ProtocolInfo from "../ProtocolInfo";
import { correctImage } from "../ProtocolInfo/TestCard";

interface Props {
  isProtocolInfoShowed: boolean;
  setIsProtocolInfoShowed: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CounterInfo: React.FC<Props> = ({
  isProtocolInfoShowed,
  setIsProtocolInfoShowed,
}) => {
  const { counter, hasProtocol } = useSelector(
    (state: RootState) => state.projects
  );

  return (
    <>
      {counter ? (
        <div className="flex flex-col gap-y-10 pb-12">
          <p className="text-[#2B7AF1] text-3xl font-extrabold">
            Результат повірки
          </p>
          <div className="flex flex-row gap-x-6 max-sm:flex-col items-center">
            <div className="p-2 bg-gray-200 rounded-lg h-fit">
              <img
                className="rounded-lg p-1"
                width={1000}
                src={`data:image/png;base64,${correctImage(counter?.Image)}`}
              ></img>
            </div>

            <div className="shadow overflow-hidden rounded border-b border-gray-200 w-full">
              <table className="min-w-full h-full bg-white">
                <thead className="bg-[#2B7AF1] text-white">
                  <tr>
                    <th className="w-1/3 text-left py-2 px-4 uppercase font-semibold text-sm">
                      Назва
                    </th>
                    <th className="w-1/3 text-left py-2 px-4 uppercase font-semibold text-sm">
                      Значення
                    </th>
                  </tr>
                </thead>
                <tbody className="text-gray-700">
                  {!counter.IsPaid && (
                    <tr className="bg-gradient-to-r from-red-500 to-orange-600">
                      <td
                        colSpan={2}
                        className="w-1/3 text-white py-2 px-4 text-sm font-semibold text-center"
                      >
                        Здійсніть оплату щоб отримати результат повірки!
                      </td>
                    </tr>
                  )}
                  <tr className="border-b">
                    <td className="w-1/3 text-left py-2 px-4 text-sm font-semibold">
                      Статус лічильника
                    </td>
                    <td
                      className={`w-1/3 text-left py-2 px-4 text-sm font-semibold ${
                        counter?.Result === "GUD"
                          ? "text-green-500"
                          : counter?.Result === "BAD"
                          ? "text-red-500"
                          : counter.IsPaid
                          ? "text-yellow-500"
                          : "text-red-500"
                      }`}
                    >
                      {counter?.Result === "GUD"
                        ? "Придатний"
                        : counter?.Result === "BAD"
                        ? "Не придатний"
                        : counter.IsPaid
                        ? "В обробці"
                        : "Очікує оплати"}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="w-1/3 text-left py-2 px-4 font-semibold text-sm">
                      Номер лічильника
                    </td>
                    <td className="w-1/3 text-left py-2 px-4 text-sm">
                      {counter?.CounterNumber}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="w-1/3 text-left py-2 px-4 text-sm font-semibold">
                      Тип лічильника
                    </td>
                    <td className="w-1/3 text-left py-2 px-4 text-sm">
                      {counter?.Type}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="w-1/3 text-left py-2 px-4 text-sm font-semibold">
                      Тип водопостачання
                    </td>
                    <td className="w-1/3 text-left py-2 px-4 text-sm">
                      {counter?.serviceType === 1
                        ? "Холодна вода"
                        : "Гаряча вода"}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="w-1/3 text-left py-2 px-4 text-sm font-semibold">
                      Рік виробництва
                    </td>
                    <td className="w-1/3 text-left py-2 px-4 text-sm">
                      {counter?.Year}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="w-1/3 text-left py-2 px-4 text-sm font-semibold">
                      Покази на момент повірки
                    </td>
                    <td className="w-1/3 text-left py-2 px-4 text-sm">
                      {counter.Liter}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="w-1/3 text-left py-2 px-4 text-sm font-semibold">
                      Номер документу про повірку
                    </td>
                    <td className="w-1/3 text-left py-2 px-4 text-sm">
                      {counter.InstallmentNumber}-{counter.FileNumber}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="w-1/3 text-left py-2 px-4 text-sm font-semibold">
                      Дата документу про повірку
                    </td>
                    <td className="w-1/3 text-left py-2 px-4 text-sm">
                      {hasProtocol ? processDate(counter?.Date) : "-"}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="w-1/3 text-left py-2 px-4 text-sm font-semibold">
                      Дата чинності документу про повірку
                    </td>
                    <td className="w-1/3 text-left py-2 px-4 text-sm">
                      {" "}
                      {hasProtocol && counter.Result === "GUD"
                        ? processDate(counter?.Date, true)
                        : "-"}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="w-1/3 text-left py-2 px-4 text-sm font-semibold">
                      Дата протоколу повірки
                    </td>
                    <td className="w-1/3 text-left py-2 px-4 text-sm">
                      {processDate(counter?.Date)}
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="w-1/3 text-left py-2 px-4 text-sm font-semibold">
                      Номер протоколу повірки
                    </td>
                    <td className="w-1/3 text-left py-2 px-4 text-sm ">
                      {counter?.FileNumber}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="p-4 gap-x-2 flex flex-row">
                <button
                  disabled={!hasProtocol}
                  onClick={() => setIsProtocolInfoShowed(!isProtocolInfoShowed)}
                  className={`py-1 px-6 text-white shadow-md rounded-md bg-gradient-to-r from-[#2B7AF1] to-[#5a9cff] ${
                    !hasProtocol ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                >
                  {isProtocolInfoShowed
                    ? "Сховати протокол"
                    : "Електронний протокол"}
                </button>
                <a
                  href={
                    counter.ProtocolFileName
                      ? `https://api.povirka.mk.ua/storage/${counter.ProtocolFileName}`
                      : undefined
                  }
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button
                    className={`py-1 px-6 text-white shadow-md rounded-md bg-gradient-to-r from-[#2B7AF1] to-[#5a9cff] ${
                      !counter.ProtocolFileName
                        ? "opacity-50 cursor-not-allowed"
                        : ""
                    }`}
                    disabled={!counter.ProtocolFileName}
                  >
                    Завантажити документ про повірку
                  </button>
                </a>
              </div>
            </div>
          </div>
          {isProtocolInfoShowed && <ProtocolInfo counter={counter} />}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
