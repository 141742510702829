import React from "react";
import { hydrate, render } from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import Home from "./components/Home";
import Info from "./components/Info";

// Создание маршрутизатора
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "/info",
        element: <Info />,
      },
    ],
  },
  // Перенаправление для всех несуществующих маршрутов
  {
    path: "*",
    element: <Navigate to="/" />,
  },
]);

const rootElement = document.getElementById("root");

if (rootElement?.hasChildNodes()) {
  hydrate(
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>,
    rootElement
  );
} else {
  render(
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>,
    rootElement
  );
}

reportWebVitals();
