import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import CardInfo from "./CardInfo";
import { TestCard, correctImage } from "./TestCard";
import { ISubscribe } from "../../../types";

interface Props {
  counter: ISubscribe;
}

const ProtocolInfo: React.FC<Props> = ({ counter }) => {
  return (
    <div className="flex flex-row gap-2 max-sm:flex-col">
      <div className="flex flex-col gap-y-1">
        {/* <div className={`text-center rounded-md text-white font-semibold`}>
              {counter?.Result === "GUD" ? (
                <CardInfo
                  cStyle="bg-green-600"
                  title="Результат повірки"
                  value="Придатний"
                />
              ) : (
                <CardInfo
                  cStyle="bg-red-600"
                  title="Результат повірки"
                  value="Непридатний"
                />
              )}
            </div> */}
        <div
          className={`text-center  rounded-md text-white font-semibold ${
            counter?.Result === "GUD" ? "bg-green-600" : "bg-red-600"
          }`}
        >
          {counter?.Result === "GUD" ? "Придатний" : "Непридатний"}
        </div>
        <CardInfo title="Файл протоколу" value={counter.FileNumber} />
        <CardInfo title="Час повірки" value={counter.Date} />
        <div className="flex flex-row w-full gap-x-1">
          <CardInfo
            title="Температура води, °C"
            value={counter.Temperature}
            wrapStyle="flex-1"
          />
          <CardInfo
            title="Умови НС"
            value={`${counter.TVozd}°C / ${counter.Vlagnost}%`}
            wrapStyle="flex-1"
          />
        </div>
        <CardInfo
          title="Призначення лічильника"
          value={`${counter.serviceType === 1 ? "Холодна" : "Гаряча"}`}
        />
        <CardInfo title="Накопичений об'єм, м³" value={counter.Liter} />
        <CardInfo title="Діаметр лічильника" value={counter.Diametr} />
        <div className="flex flex-row w-full gap-x-1">
          <CardInfo
            title="Рік випуску"
            value={counter.Year}
            wrapStyle="flex-1 flex-grow"
          />
          <CardInfo
            title="Тип лічильника"
            value={counter.Type}
            wrapStyle="flex-1 flex-grow"
          />
        </div>
        {/* <CardInfo title="ДСТУ лічильника" value={counter.FullStandName} /> */}
        <CardInfo title="Лічильник №" value={counter.CounterNumber} />
        <CardInfo title="ПІБ Виконавця" value={counter.OSurname} />
        <div>
          <img
            className="rounded-lg"
            width={600}
            src={`data:image/png;base64,${correctImage(counter?.Image)}`}
          ></img>
        </div>
        <div>
          <img
            className="rounded-lg"
            width={600}
            src={`data:image/png;base64,${correctImage(counter?.TestPhoto)}`}
          ></img>
        </div>
      </div>

      <TestCard
        title="Тест 1"
        testAllowableError={counter.Test10AllowableError}
        testConsuptionStatus={counter.Test10ConsuptionStatus}
        testActualConsumption={counter.Test10actualConsumption}
        testInitialValue={counter.Test10initialValue}
        testEndValue={counter.Test10evdValue}
        testTestDuration={counter.Test10testDuration}
        testTestNumber={counter.Test10testNumber}
        testVolumeOfStandard={counter.Test10volumeOfStandard}
        testBeginPhoto={counter?.Test10beginPhoto}
        testEndPhoto={counter?.Test10endPhoto}
        generalConsumption="1.50"
      />

      {counter?.Test11beginPhoto && counter?.Test11endPhoto && (
        <TestCard
          title="Тест 1 Повтор"
          testAllowableError={counter.Test11AllowableError!}
          testConsuptionStatus={counter.Test11ConsuptionStatus!}
          testActualConsumption={counter.Test11actualConsumption!}
          testInitialValue={counter.Test11initialValue!}
          testEndValue={counter.Test11evdValue!}
          testTestDuration={counter.Test11testDuration!}
          testTestNumber={counter.Test11testNumber!}
          testVolumeOfStandard={counter.Test11volumeOfStandard!}
          testBeginPhoto={counter?.Test11beginPhoto}
          testEndPhoto={counter?.Test11endPhoto}
          generalConsumption="1.50"
        />
      )}

      <TestCard
        title="Тест 2"
        testAllowableError={counter.Test20AllowableError}
        testConsuptionStatus={counter.Test20ConsuptionStatus}
        testActualConsumption={counter.Test20actualConsumption}
        testInitialValue={counter.Test20initialValue}
        testEndValue={counter.Test20evdValue}
        testTestDuration={counter.Test20testDuration}
        testTestNumber={counter.Test20testNumber}
        testVolumeOfStandard={counter.Test20volumeOfStandard}
        testBeginPhoto={counter?.Test20beginPhoto}
        testEndPhoto={counter?.Test20endPhoto}
        generalConsumption="0.15"
      />

      {counter?.Test21beginPhoto && counter?.Test21endPhoto && (
        <TestCard
          title="Тест 2 Повтор"
          testAllowableError={counter.Test21AllowableError!}
          testConsuptionStatus={counter.Test21ConsuptionStatus!}
          testActualConsumption={counter.Test21actualConsumption!}
          testInitialValue={counter.Test21initialValue!}
          testEndValue={counter.Test21evdValue!}
          testTestDuration={counter.Test21testDuration!}
          testTestNumber={counter.Test21testNumber!}
          testVolumeOfStandard={counter.Test21volumeOfStandard!}
          testBeginPhoto={counter?.Test21beginPhoto}
          testEndPhoto={counter?.Test21endPhoto}
          generalConsumption="0.15"
        />
      )}

      <TestCard
        title="Тест 3"
        testAllowableError={counter.Test30AllowableError}
        testConsuptionStatus={counter.Test30ConsuptionStatus}
        testActualConsumption={counter.Test30actualConsumption}
        testInitialValue={counter.Test30initialValue}
        testEndValue={counter.Test30evdValue}
        testTestDuration={counter.Test30testDuration}
        testTestNumber={counter.Test30testNumber}
        testVolumeOfStandard={counter.Test30volumeOfStandard}
        testBeginPhoto={counter?.Test30beginPhoto}
        testEndPhoto={counter?.Test30endPhoto}
        generalConsumption="0.06"
      />

      {counter?.Test31beginPhoto && counter?.Test31endPhoto && (
        <TestCard
          title="Тест 2 Повтор"
          testAllowableError={counter.Test31AllowableError!}
          testConsuptionStatus={counter.Test31ConsuptionStatus!}
          testActualConsumption={counter.Test31actualConsumption!}
          testInitialValue={counter.Test31initialValue!}
          testEndValue={counter.Test31evdValue!}
          testTestDuration={counter.Test31testDuration!}
          testTestNumber={counter.Test31testNumber!}
          testVolumeOfStandard={counter.Test31volumeOfStandard!}
          testBeginPhoto={counter?.Test31beginPhoto}
          testEndPhoto={counter?.Test31endPhoto}
          generalConsumption="0.06"
        />
      )}
    </div>
  );
};

export default ProtocolInfo;
